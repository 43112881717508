import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import bus from "@/bus";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userName:               localStorage.getItem('userName') || '',
        orgName:               localStorage.getItem('orgName') || '',
        userId:                 localStorage.getItem('userId') || '',
        token:                  localStorage.getItem('token') || '',
        rules:                  JSON.parse(localStorage.getItem('rules')) || [],
        etherId:                localStorage.getItem('ether_id') || 0,
        filterPanelProducer:    localStorage.getItem('filter_panel_producer') || 'all',
        filterPanelProducer2:   localStorage.getItem('filter_panel_producer2') || 'work',
        local:                  JSON.parse(localStorage.getItem('local')) || {},
        locale:                 localStorage.getItem('locale') || 'en_US',
        cView:                  '',
        currentPhone:           '',
        acceptTicket:           0,
        TZ:                     Intl.DateTimeFormat(undefined,{timeZoneName: "short"}).formatToParts().find((i) => i.type === "timeZoneName").value
    },

    mutations: {
        auth_request(state){
            state.status = 'loading';
        },

        auth_success(state, data) {
            state.status   = 'success';
            state.token    = data.token;
            state.userId   = data.userId;
            state.userName = data.userName;
            state.orgName = data.orgName;
            state.rules    = data.rules;
            state.local    = data.local;
            state.locale   = data.locale;
        },

        auth_error(state) {
            state.token    = '';
            state.status   = 'error';
            state.userName = '';
            state.orgName = '';
            state.rules    = [];
            state.local    = {};
        },

        logout(state) {
            state.token    = '';
            state.status   = '';
            state.userName = '';
            state.orgName = '';
            state.rules    = [];
//            state.local    = {};
        },

        setLocal(state, value) {
            state.local = value;
        },

        setLocale(state, value) {
            state.locale = value;
        },

        setTZ(state, value) {
            state.TZ = value;
        },
    },

    actions: {
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios.post(process.env.VUE_APP_PREFIX_URL + '/api/login?ts='+Date.now(), user, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => {
                        if (process.env.NODE_ENV !== 'production') console.log("Auth: ", resp);
                        if (resp.data.success) {

                            const token     = resp.data.accessToken;
//                            const ref   = resp.data.refreshToken;
                            const userId    = resp.data.user_id;
                            const userName  = resp.data.user;
                            const orgName  = resp.data.org_name;
                            const rules     = JSON.parse(resp.data.rules);
                            const loc       = resp.data.local;
                            const locale    = resp.data.locale;

                            localStorage.setItem('token', token);
                            localStorage.setItem('userId', userId);
                            localStorage.setItem('userName', userName);
                            localStorage.setItem('orgName', orgName);
                            localStorage.setItem('rules', resp.data.rules);
                            localStorage.setItem('local', JSON.stringify(resp.data.local));
                            localStorage.setItem('locale', resp.data.locale);
                            axios.defaults.headers.common['Authorization'] = token;
                            commit('auth_success', { token: token, userId: userId, userName: userName, orgName: orgName, rules: rules, local: loc, locale: locale });
                            resolve(resp);
                        }else{
                            reject(resp);
                        }
                    })
                    .catch(err => {
                        commit('auth_error');
                        localStorage.removeItem('token');
                        reject(err);
                    })
            })
        },

        logout({commit}) {
            return new Promise((resolve) => {
                commit('logout');
                localStorage.removeItem('token');
                localStorage.removeItem('userId');
                localStorage.removeItem('userName');
                localStorage.removeItem('orgName');
                localStorage.removeItem('locale');
                delete axios.defaults.headers.common['Authorization'];
                resolve();
            })
        },

        setLocale({commit}, value) {
            return new Promise((resolve) => {
                commit('setLocale', value);
                localStorage.setItem('locale', value);
                resolve();
            })
        },

        setTZ({commit}, value) {
            return new Promise((resolve) => {
                commit('setTZ', value);
                resolve();
            })
        },
    },

    getters : {
        token: state => state.token,

        getUserId: state => state.userId,

        isLoggedIn: state => !!state.token,

        authStatus: state => state.status,

        getUserName: state => state.userName,

        getOrgName: state => state.orgName,

        getLocal: state => state.local,

        getLocale: state => state.locale,

        getTZ: state => state.TZ,

        getRule: (state) => (name) => {
            if (state.rules.length === 0) return false;
            var _rule = state.rules.find(rule => rule.name === name);
            if (typeof _rule !== 'undefined') {
                return _rule.access;
            }
            return false;
        }

    }
})
