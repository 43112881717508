<template>
    <div>
        <br/>
        <b-card>
            <div class="row">
                <div class="col-sm-12">
                    <table>
                        <tr>
                            <td>
                                <button class="btn btn-success" @click="createCheckRegistry()" >{{$t('main.add')}}</button>
                            </td>
                            <td>
                                <button class="btn btn-primary"  @click="showzreport()" style="margin-left: 10px">{{$t('main.z_reports')}}</button>
                            </td>
                            <td>
                                <button class="btn btn-info" @click="loadData()" style="margin-left: 10px"><b-icon-arrow-clockwise></b-icon-arrow-clockwise></button>
                            </td>
                            <td>
                                <b-form-select v-model="filter.s_filter_time" :options="so.options_time" style="margin-left: 20px;width: 200px" @change="changeFilter()"></b-form-select>
                            </td>
                            <td>
                                <b-form-select v-model="filter.s_filter_view" :options="so.options_view" style="margin-left: 20px;width: 250px" @change="changeFilter()"></b-form-select>
                            </td>
                            <td>
                                <b-form-input style="margin-left: 20px;width: 250px" id="inline-form-input-name" :placeholder="$t('main.search')" v-model="filter.search" @input="searchData"></b-form-input>
                            </td>
                            <td>
                                <b-form-select v-model="filter.sourceStore"  :options="so.options_stores" :placeholder="$t('main.filter_by_warehouse')" style="margin-right: 20px" @change="changeFilter()"></b-form-select>
                            </td>
                            <td>
                                <button class="btn btn-danger"  @click="resetFilter()" style="margin-left: 10px"  :disabled="filter.s_filter_time == 'today' && filter.s_filter_view == 'all' && filter.search == '' && filter.sourceStore == ''">{{$t('main.reset_filter')}}</button>
                            </td>
                          <td>
                            <a v-if="rule_print_selected_registers && paginatedData.length > 0" type="button" style="margin-left: 10px" class="btn btn-info" :href="'https://prokassa.uz/api/checkregistry/?type=registry_all&token='+token+'&filter='+JSON.stringify(this.filter)" target="_blank" >
                              <b-icon-printer></b-icon-printer> {{$t('main.print_all_registry')}}
                            </a>
                          </td>
                            <td>
                                <b-button v-if="loading" variant="primary" disabled style="margin-left: 10px">
                                    <b-spinner small type="grow"></b-spinner>
                                    {{$t('main.please_wait_data_is_loading')}}
                                </b-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </b-card>
        <template v-if="filter.panel_datepick">
            <br/>
            <b-card>
                <div class="row" v-if="filter.panel_datepick">
                    <div class="col-sm-6">
                        <label class="mr-sm-2" for="inline-form-custom-select-pref">{{$t('main.beginning_of_period')}}: </label>
                        <b-form-datepicker
                                v-model="filter.date_min_value"
                                size="sm"
                                class="mb-2"
                                style="margin-right: 20px"
                                @input = "loadData()"
                                ></b-form-datepicker>
                    </div>
                    <div class="col-sm-6">
                        <label class="mr-sm-2" for="inline-form-custom-select-pref">{{$t('main.end_of_period')}}: </label>
                        <b-form-datepicker
                                v-model="filter.date_max_value"
                                size="sm"
                                class="mb-2"
                                @input = "loadData()"
                                ></b-form-datepicker>
                    </div>
                </div>
            </b-card>
        </template>
        <div class="row">
            <div class="col-sm-12 text-danger text-center">
                <p></p>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 text-center">
                <template v-if="pageCount>1">
                    <nav class="text-center">
                        <ul class="pagination">
                            <li class="page-item">
                                <a class="page-link" aria-label="Previous" @click="prevPage">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                            <li class="page-item" v-for="n in pageCount" v-bind:key="n" v-bind:class="{active: (n-1)==pageNumber}">
                                <a class="page-link" @click="goPage(n)">{{n}}</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" aria-label="Next" @click="nextPage">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </template>
                <table class="table" >
                    <thead>
                    <tr>
                        <th scope="col">{{$t('main.registry_number')}}</th>
                        <th scope="col">{{$t('main.date_of_creation')}}</th>
                        <th scope="col">{{$t('main.store')}}</th>
                        <th scope="col">{{$t('main.number_of_orders')}}</th>
                        <th scope="col">{{$t('main.register_amount')}}<br/>{{$t('main.сhecks_punched')}}</th>
                        <th scope="col">{{$t('main.note')}}</th>
                        <th scope="col">{{$t('main.divergence')}}</th>
                        <th scope="col">{{$t('main.status')}}</th>
                        <th v-if="rule_tv_admin" scope="col">{{$t('main.owner')}}</th>
                        <th scope="col">{{$t('main.operations')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in paginatedData" :key="item.id">
                        <td> <router-link class="nav-link" :to="'/checkregistry/' + item.id">{{item.number}}</router-link></td>
                        <td>{{item.ctime}}</td>
                        <td>{{item.store}}</td>
                        <td class="text-right">{{item.order_count}}</td>
                      <td class="text-right">{{item.registry_total | formatNumber }}<br/>
                        <template v-if="item.registry_total == item.total_check">
                          <b><span class="text-success">{{item.total_check | formatNumber}}</span></b>
                        </template>
                        <template v-else>
                          <b><span class="text-danger">{{item.total_check | formatNumber}}</span></b>
                        </template>
                      </td>

                        <td>{{item.note}}</td>
                        <td>
                            <template v-if="item.diff > 0"><span class="text-danger">{{$t('check.yes')}}</span></template>
                            <template v-if="item.diff == 0"><span class="text-success">{{$t('check.no')}}</span></template>
                            <template v-if="item.diff < 0">-</template>
                        </td>
                        <td>
                            <template v-if="item.status == 'new'"><span class="text-info">{{$t('main.editing')}}</span></template>
                            <template v-if="item.status == 'processing'"><span class="text-primary">{{$t('main.sending_checks')}}</span></template>
                            <template v-if="item.status == 'done'"><span class="text-success">{{$t('main.registry_processed')}}</span></template>
                            <template v-if="item.status == 'error'"><span class="text-danger">{{$t('main.processing_error')}}</span></template>
                        </td>

                        <td v-if="rule_tv_admin">{{item.owner}}</td>
                        <td>
                            <template v-if="item.status == 'done'">
                            <a type="button" class="btn btn-sm btn-info" :href="'https://prokassa.uz/api/checkregistry/'+item.id+'/?type=registry&token='+token" target="_blank" >
                                <b-icon-printer></b-icon-printer>
                            </a>
                            </template>
                            <template v-else>
                                <button disabled="disabled" class="btn btn-sm btn-info">
                                    <b-icon-printer></b-icon-printer>
                                </button>
                            </template>
                            <button class="btn btn-sm btn-danger" @click="deleteCheckRegistry(item)" :disabled="item.status != 'new' && !rule_supervision" style="margin-left: 5px">
                                <b-icon-trash></b-icon-trash>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="text-right">{{ totalQty }}</td>
                        <td class="text-right">{{ totalTotal | formatNumber }}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tfoot>
                </table>

            </div>
        </div>
        <br/>
        <div class="row">
            <div class="col-sm-12 text-center">
            </div>
        </div>
        <b-modal id="bv-modal-zreport" hide-header size="xl">
            <template #modal-title>
                {{$t('main.cash_management')}}
            </template>
            <template #modal-footer>
                <b-col class="text-right">
                    <b-form-select v-model="store_id" :options="so.stores"></b-form-select>
                </b-col>
                <b-col class="text-right">

                    <b-button style="margin-left: 10px" class="mt-3 btn btn-success" :disabled="store_id==0" @click="openZreport">{{$t('main.open_z_report')}}</b-button>
                    <b-button style="margin-left: 10px" class="mt-3 btn btn-danger" :disabled="store_id==0" @click="closeZreport">{{$t('main.close_z_report')}}</b-button>
                    <b-button style="margin-left: 10px" class="mt-3 btn btn-info" @click="loadZreport"><b-icon-arrow-clockwise></b-icon-arrow-clockwise></b-button>
                    <b-button style="margin-left: 10px" class="mt-3" @click="$bvModal.hide('bv-modal-zreport')">{{$t('main.close')}}</b-button>
                </b-col>
            </template>
            <div class="d-block">
                <b-card>
                    <b-overlay :show="loading_z_report">
                    <table width="100%">
                        <tr>
                            <th>{{$t('main.date')}}</th>
                            <th>{{$t('main.store')}}</th>
                            <th>{{$t('main.operations')}}</th>
                            <th>{{$t('main.status')}}</th>
                        </tr>
                        <tr v-for="(item, index) in data_z_report" :key="item.id">
                            <td>{{item.ctime}}</td>
                            <td>{{item.store}}</td>
                            <td>
                                <template v-if="item.type == 'openZreport'">
                                    {{$t('main.opening_registry')}}
                                </template>
                                <template v-if="item.type == 'closeZreport'">
                                    {{$t('main.closing_registry')}}
                                </template>
                            </td>
                            <td>
                                <template v-if="item.status == 'create'">
                                    {{$t('main.request_is_pending')}}
                                </template>
                                <template v-if="item.status == 'processing'">
                                    {{$t('main.request_is_being_processed')}}
                                </template>
                                <template v-if="item.status == 'done'">
                                    <span class="text-success">{{$t('main.request_completed_successfully')}}</span>
                                </template>
                                <template v-if="item.status == 'error'">
                                    <span class="text-danger">{{$t('main.error')}}: {{item.message}}</span>
                                </template>

                            </td>
                        </tr>
                    </table>
                    </b-overlay>
                </b-card>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Axios from 'axios';
    import bus from "@/bus";
    import debounce from 'debounce';

    Vue.filter("formatNumber", function (value) {
        return new Intl.NumberFormat().format(value);
    });

    export default {
        name: 'CheckRegistry',
        data() {
            return {
                data: [],
                data_z_report: [],
                pageNumber: 0,
                loading: false,
                loading_z_report: false,
                so: {
                    options_time: [
                        {value: 'all', text: this.$t('main.show_all')},
                        {value: 'today', text: this.$t('main.today')},
                        {value: 'yesterday', text: this.$t('main.yesterday')},
                        {value: 'days7', text: this.$t('main.in_7_days')},
                        {value: 'days30', text: this.$t('main.current_month')},
                        {value: 'manual', text: this.$t('main.period')},
                    ],

                    options_view: [
                        {value: 'work', text: this.$t('main.show_unclosed')},
                        {value: 'fail', text: this.$t('main.show_only_with_discrepancies')},
                        {value: 'done', text: this.$t('main.show_only_successful_ones')},
                        {value: 'all', text: this.$t('main.show_all')}
                    ],

                    options_stores:     [],
                    stores: [],
                },
                filter: {
                    date_min_value:     '',
                    date_max_value:     '',

                    panel_datepick:     false,

                    search:             '',
                    comitent:           '',
                    sourceStore:        0,
                    destinationStore:   0,

                    s_filter_time:      'today',
                    s_filter_view:      'all',
                    s_filter_custom:    'none'
                },
                skipDone: true,
                filterStore: 0,
                store_id: 0
            }
        },

        created: function() {

        },

        metaInfo() {
            return {
                title: this.$t('check.title')
            }
        },

        mounted() {
            bus.$emit('set-header', this.$t('check.title'));

            this.loadStores();
            if (typeof localStorage.filter_checkregistry !== 'undefined') {
                this.filter = JSON.parse(localStorage.filter_checkregistry);
                this.filter.date_min_value   = new Date().toISOString().slice(0, 10);
                this.filter.date_max_value   = new Date().toISOString().slice(0, 10);
                if (this.filter.panel_search && this.filter.search.length > 0) this.loadDataSearch();
                else this.loadData();
            }else this.loadData();

        },

        props: {
            size: {
                type: Number,
                required: false,
                default: 30
            }
        },

        methods: {
            nextPage() {
                if (this.pageNumber != this.pageCount - 1) this.pageNumber++;
            },

            prevPage() {
                if (this.pageNumber != 0) this.pageNumber--;
            },

            goPage(n) {
                this.pageNumber = n - 1;
            },

            changeFilter() {
                if (this.filter.s_filter_time == 'manual') {
                        this.filter.panel_datepick   = true;
                } else {
                        this.filter.panel_datepick = false;
                }

                this.loadData();

                if (this.pageNumber != 0) this.pageNumber = 0;
            },

            resetFilter() {
                this.filter.s_filter_time        = 'today';
                this.filter.s_filter_view        = 'all';
                this.filter.panel_datepick       = false;
                this.filter.search               = '';
                this.changeFilter();
            },

            showzreport(){
                this.loadZreport(true);
            },

            loadZreport(flag = false){
                Axios
                        .get(this.prefix_url + '/api/checkregistry?ts='+Date.now()+'&type=zreport')
                        .then(response => {
                            this.data_z_report = response.data.data;
                            if (flag) this.$bvModal.show('bv-modal-zreport');
                });
            },

            openZreport(){
                Axios
                   .post(this.prefix_url + '/api/checkregistry/?ts=' + Date.now(), {
                        method: 'openzreport',
                        store_id: this.store_id
                    }).then(res => {
                        if (res.data.success) {
                            this.loadZreport(false);
                        }else{
                            bus.$emit('view-message', {message: res.data.message, variant: 'danger'});
                        }
                });
            },

            closeZreport(){
                Axios
                    .post(this.prefix_url + '/api/checkregistry/?ts=' + Date.now(), {
                        method: 'closezreport',
                        store_id: this.store_id
                    }).then(res => {
                        if (res.data.success) {
                            this.loadZreport(false);
                        }else{
                            bus.$emit('view-message', {message: res.data.message, variant: 'danger'});
                        }
                });
            },

            loadData(){                  // загрузка данных с backend
                this.loading = true;
                Axios
                   .get(this.prefix_url + '/api/checkregistry?ts='+Date.now()+'&type=list&filter='+this.filter.s_filter_time+'&filter2='+this.filter.s_filter_view+'&min='+this.filter.date_min_value+'&max='+this.filter.date_max_value+'&filter_panel='+this.filter.panel_store+'&sourceStore='+this.filter.sourceStore+'&destinationStore='+this.filter.destinationStore+'&filter_comitent='+this.filter.comitent)
                   .then(response => {
                        this.data = response.data.data;
                        this.loading = false;
                });
            },

            loadStores(){
                Axios
                    .get(this.prefix_url + '/api/checkregistry?ts='+Date.now()+'&type=store')
                    .then(response => {
                    Vue.set(this.so, 'options_stores', response.data.data);
                });
                Axios
                    .get(this.prefix_url + '/api/checkregistry?ts='+Date.now()+'&type=store2')
                    .then(response => {
                        Vue.set(this.so, 'stores', response.data.data);
                });
            },

            searchData(){
                this.loading = true;
                debounce((function (_this, event) {
                    _this.$http
                        .get(_this.prefix_url + '/api/checkregistry?ts=' + Date.now() + '&type=query&query='+event.target.value+'&tz='+Intl.DateTimeFormat().resolvedOptions().timeZone)
                        .then(response => {
                            _this.data = response.data.data;
                            _this.loading = false;
                        });
                })(this, event), 200);
            },

            loadDataSearch(){
                this.loading = true;
                let url = this.prefix_url + '/api/checkregistry?ts='+Date.now()+'&type=query&query=' + this.filter.search;
//                console.log('load url', url);

                Axios
                    .get(url)
                    .then(response => {
                        this.data = response.data.data;
                        this.loading = false;
                });
            },

            createCheckRegistry(){
                Axios
                    .post(this.prefix_url + '/api/checkregistry/?ts=' + Date.now(), {
                        method: 'add_checkregistry'
                    })
                    .then(res => {
                        if (res.data.success) {
                            let checkregistry_id = res.data.checkregistry_id;
                            this.$router.push("/checkregistry/" + checkregistry_id);
                        }else{
                            bus.$emit('view-message', {message: res.data.message, variant: 'danger'});
                        }
                });
            },

            deleteCheckRegistry(item){
                if (confirm(this.$t('check.are_you_sure_you_want_to_delete_check_registry', {number: item.id}))) {
                    this.removeCheckRegistry(item);
                }
            },

            removeCheckRegistry(item){
                Axios
                    .post(this.prefix_url + '/api/checkregistry/?ts=' + Date.now(), {
                        method: 'del_checkregistry',
                        checkregistry_id: item.id
                    })
                    .then(res => {
                        if (res.data.success) {
                            let index = this.data.map(function (item) {
                                return item.id;
                            }).indexOf(item.id);
                            this.data.splice(index, 1);
                        }else{
                            bus.$emit('view-message', {message: res.data.message, variant: 'danger'});
                        }
                });
            },
        },

        computed : {
            totalQty() {
                let total = 0;
                this.data.forEach(function (item) {
                    total += parseFloat(item.order_count);
                });
                return total;
            },

            totalTotal() {
                let total = 0;
                let _this = this;
                this.data.forEach(function (item) {
                    total += parseFloat(item.registry_total);
                });
                return total;
            },

            pageCount() {
                let l = this.data.length,
                    s = this.size;

                return Math.ceil(l / s);
            },

            paginatedData() {
                const start = this.pageNumber * this.size,
                    end = start + this.size;
                return this.data.slice(start, end);
            },

            rule_tv_admin: function (){return this.$store.getters.getRule('tv_admin') },
            rule_supervision: function (){return (this.$store.getters.getUserId == 2) },
            rule_print_selected_registers: function (){return this.$store.getters.getRule('print_selected_registers') },
            token: function(){ return this.$store.getters.token },

            prefix_url:function () { return process.env.VUE_APP_PREFIX_URL }

        },

        watch: {
            filter: {
                handler(val)
                {
                    let j = JSON.stringify(this.filter);

                    localStorage.filter_checkregistry = j;
                },
                deep: true
            }
        }
    }
</script>
